/**
 * @file It contains scripts for channel page
 */
import { mapActions, mapGetters, mapMutations } from "vuex"
import { format, downloadFile } from "@/utils"
import ThemisInput from "@/components/shared/input"
import ThemisInputAppend from "@/components/shared/input-append"
import ThemisInputAppendOuter from "@/components/shared/input-append-outer"
import ThemisDecision from "@/components/shared/decision"
import ThemisFile from "@/components/shared/file"
import QRCode from "qrcode"
import { getHeadersForTable, getFootersForTable } from "@/utils/table"
import { TABLE_NAMES, ARRAY, AUTOMATION_EVENT, AUTOMATION_RULE_NAME, ANCHOR_LINKS } from "@/constants"

export default {
  name      : "Channel",
  components: {
    ThemisInputAppend,
    ThemisInputAppendOuter,
    ThemisDecision,
    ThemisFile,
    ThemisInput
  },
  data: () => ({
    localChannel                                     : undefined,
    isNameInputFocussed                              : false,
    isSkipOrganisationCodeForWebInputFocussed        : false,
    isTranslationPreferenceInputFocussed             : false,
    isOrganisationCodeInputFocussed                  : false,
    isOverrideSkipOrganisationCodeForWebInputFocussed: false,
    isOverrideTranslationPreferenceInputFocussed     : false,
    isSecuritySettingsFocussed                       : false,
    isTranslationSettingFocussed                     : false,
    showDisableChannelDialog                         : false,
    isDisplayNameInputFocussed                       : false,
    isLinkDisplayNameInputFocussed                   : false,
    isLinkInputFocussed                              : false,
    translationConfigurationsForDisplay              : new Array,
    channelLogo                                      : undefined,
    isLogoRemovable                                  : false,
    allMachineTranslatableLanguagesEnabled           : false,
    channelConfigurationTab                          : null,
    showAddFormTemplateDialog                        : false,
    formTemplatesToBeAdded                           : [],
    editChannelFormTemplate                          : false,
    showRemoveFormTemplateDialog                     : false,
    formTemplateToBeRemoved                          : null,
    showAutomationAddDialog                          : false,
    currentAutomationAddStep                         : 1,
    fieldIdForAutomation                             : null,
    showRemoveAutomationDialog                       : false,
    automationRules                                  : [],
    isEditingAutomationRules                         : false,
    isDeletingAutomationRules                        : false,
    emptyAutomationRule                              : {
      fieldValues: [],
      domainId   : null
    },
    tabs: {
      [ANCHOR_LINKS.WEB_AND_APP]             : 0,
      [ANCHOR_LINKS.REPORTER_INTAKE_FORM]    : 1,
      [ANCHOR_LINKS.TRANSLATION_AND_LANGUAGE]: 2
    }
  }),
  computed: {
    ...mapGetters({
      clientName                                        : "auth/clientName",
      channels                                          : "channels/channels",
      translationPreferences                            : "translationPreferences/translationPreferences",
      configurations                                    : "configurations/configurations",
      availableOrganisationCodes                        : "organisationCodes/availableOrganisationCodes",
      domains                                           : "domains/domains",
      isUpdatingName                                    : "channels/isUpdatingName",
      isNameUpdated                                     : "channels/isNameUpdated",
      isUpdatingDisplayName                             : "channels/isUpdatingDisplayName",
      isDisplayNameUpdated                              : "channels/isDisplayNameUpdated",
      isUpdatingLink                                    : "channels/isUpdatingLink",
      isLinkUpdated                                     : "channels/isLinkUpdated",
      isUpdatingLinkDisplayName                         : "channels/isUpdatingLinkDisplayName",
      isLinkDisplayNameUpdated                          : "channels/isLinkDisplayNameUpdated",
      isUpdatingTranslationPreference                   : "channels/isUpdatingTranslationPreference",
      isTranslationPreferenceUpdated                    : "channels/isTranslationPreferenceUpdated",
      isUpdatingOrganisationCode                        : "channels/isUpdatingOrganisationCode",
      isOrganisationCodeUpdated                         : "channels/isOrganisationCodeUpdated",
      nameUpdateError                                   : "channels/nameUpdateError",
      displayNameUpdateError                            : "channels/displayNameUpdateError",
      isEnabledUpdated                                  : "channels/isEnabledUpdated",
      isUpdatingEnabled                                 : "channels/isUpdatingEnabled",
      languages                                         : "languages/languages",
      translationConfigurations                         : "channels/translationConfigurations",
      isUpdatingTranslationConfigurations               : "channels/isUpdatingTranslationConfigurations",
      isTranslationConfigurationsUpdated                : "channels/isTranslationConfigurationsUpdated",
      isChannelLogoAdded                                : "channels/isChannelLogoAdded",
      isAddingChannelLogo                               : "channels/isAddingChannelLogo",
      isChannelLogoRemoved                              : "channels/isChannelLogoRemoved",
      formTemplates                                     : "formTemplates/formTemplates",
      fieldsV2                                          : "fields/fieldsV2",
      formTemplateConfigurations                        : "formTemplateConfigurations/formTemplateConfigurations",
      formTemplatesOfChannels                           : "channels/formTemplatesOfChannels",
      isAddingChannelFormTemplates                      : "channels/isAddingChannelFormTemplates",
      channelFormTemplatesAdded                         : "channels/channelFormTemplatesAdded",
      isRemovingChannelFormTemplate                     : "channels/isRemovingChannelFormTemplate",
      isChannelFormTemplateRemoved                      : "channels/isChannelFormTemplateRemoved",
      domainChangeOnSpeakUpIssueCreationRulesForChannels: "automations/domainChangeOnSpeakUpIssueCreationRulesForChannels",
      optionListItems                                   : "optionListItems/optionListItems",
      isAddingAutomationAndRules                        : "automations/isAddingAutomationAndRules",
      isAutomationAndRulesAdded                         : "automations/isAutomationAndRulesAdded",
      isCreatingUpdatingDeletingAutomationRules         : "automations/isCreatingUpdatingDeletingAutomationRules",
      isAutomationRulesCreatedUpdatedDeleted            : "automations/isAutomationRulesCreatedUpdatedDeleted"
    }),
    channel() {
      return this.channels.find(channel => channel.id === +this.$route.params.id)
    },
    domain() {
      return this.domains.find(domain => domain.id === this.channel.domainId)
    },
    translationPreferencesMap() {
      const translationPreferencesMap = new Object()
      for (const translationPreference of this.translationPreferences) {
        translationPreferencesMap[translationPreference.id] = translationPreference
      }
      return translationPreferencesMap
    },
    reporterUrl() {
      return format(process.env.VUE_APP_REPORTER_URL, this.clientName, this.localChannel.name)
    },
    isNameChanged() {
      return this.channel?.name !== this.localChannel?.name
    },
    isLinkChanged() {
      return this.channel?.link !== this.localChannel?.link
    },
    isLinkDisplayNameChanged() {
      return this.channel?.linkDisplayName !== this.localChannel?.linkDisplayName
    },
    isTranslationPreferenceChanged() {
      return this.channel?.translationPreferenceId !== this.localChannel?.translationPreferenceId
    },
    isOrganisationCodeChanged() {
      return this.channel?.organisationCode !== this.localChannel?.organisationCode
    },
    isNameDuplicate() {
      return this.nameUpdateError?.type === "duplicate"
    },
    isDisplayNameDuplicate() {
      return this.displayNameUpdateError?.type === "duplicate"
    },
    isDisplayNameChanged() {
      return this.channel?.displayName !== this.localChannel?.displayName
    },
    domainChangeOnSpeakUpIssueCreationRulesForChannel() {
      return this.domainChangeOnSpeakUpIssueCreationRulesForChannels[this.channel.id]
    },
    isAutomationAdded() {
      return !!this.domainChangeOnSpeakUpIssueCreationRulesForChannel?.length
    },
    automationIdForTheChannel() {
      return this.domainChangeOnSpeakUpIssueCreationRulesForChannel[0]?.automationId
    },
    fieldForAddedAutomation() {
      if (this.isAutomationAdded) {
        const automationFieldId   = this.domainChangeOnSpeakUpIssueCreationRulesForChannel[0]
          .value.conditions[0].fieldId
        this.fieldIdForAutomation = automationFieldId
        return this.fieldsMap.get(automationFieldId)
      }
    },
    initialAutomationRules() {
      const initialAutomationRulesData = []
      if (this.isAutomationAdded) {
        for (const rule of this.domainChangeOnSpeakUpIssueCreationRulesForChannel) {
          const fieldValues = []
          for (const condition of rule.value.conditions) {
            for (const fieldValue of condition.fieldValues) {
              fieldValues.push(fieldValue)
            }
          }
          if (fieldValues) {
            initialAutomationRulesData.push({
              id      : rule.id,
              fieldValues,
              domainId: rule.value.result.domainId
            })
          }
        }
      }

      return initialAutomationRulesData
    },
    initialAutomationRulesMap() {
      const initialAutomationRulesMap = new Object()
      for (const initialAutomationRule of this.initialAutomationRules) {
        initialAutomationRulesMap[initialAutomationRule.id] = initialAutomationRule
      }
      return initialAutomationRulesMap
    },
    effectiveSkipOrganisationCodeForWeb() {
      return this.localChannel.overrideAskOrganisationCodeForWeb ?
        this.localChannel.askOrganisationCodeForWeb : this.clientConfiguration.askOrganisationCodeForWeb
    },
    organisationCodesForSelection() {
      const organisationCodes = []
      if (this.localChannel.organisationCode) {
        organisationCodes.push(this.localChannel.organisationCode)
      }
      if (this.availableOrganisationCodes) {
        organisationCodes.push(...this.availableOrganisationCodes.map(organisationCode => organisationCode.id))
      }
      return organisationCodes
    },
    clientConfiguration() {
      const clientConfiguration = {}
      if (this.configurations) {
        for (const configuration of this.configurations) {
          if (configuration.key === this.$CONSTANTS.CONFIGURATIONS.ASK_ORGANISATION_CODE_FOR_WEB) {
            clientConfiguration.askOrganisationCodeForWeb = (configuration.value === "true")
          } else if (configuration.key === this.$CONSTANTS.CONFIGURATIONS.TRANSLATION) {
            clientConfiguration.translationPreferenceId = configuration.value
          }
        }
      }
      return clientConfiguration
    },
    effectiveChannelTranslationPreferenceId() {
      return this.channel?.overrideTranslationPreference ?
        this.channel.translationPreferenceId : +this.clientConfiguration.translationPreferenceId
    },
    effectiveChannelAskOrganisationCodeForWeb() {
      return this.channel?.overrideAskOrganisationCodeForWeb ?
        this.channel.askOrganisationCodeForWeb :
        this.clientConfiguration.askOrganisationCodeForWeb
    },
    enableVoiceMessagesInApp() {
      if ([this.$CONSTANTS.TRANSLATION_PREFERENCES.HUMAN_ADDED,
        this.$CONSTANTS.TRANSLATION_PREFERENCES.HUMAN_ONLY,
        this.$CONSTANTS.TRANSLATION_PREFERENCES.MACHINE_PREFERRED].includes(
        this.effectiveChannelTranslationPreferenceId)) {
        return true
      } else {
        return false
      }
    },
    languagesMap() {
      const languagesMap = new Object()
      for (const language of this.languages) {
        languagesMap[language.id] = language
      }
      return languagesMap
    },
    translationConfigurationsOfChannel() {
      return this.translationConfigurations[+this.$route.params.id]
    },
    canComputeTranslationConfigurationsForDisplay() {
      return !!(
        Object.keys(this.languagesMap).length &&
        this.translationConfigurationsOfChannel?.length &&
        this.effectiveChannelTranslationPreferenceId
      )
    },
    headersForTranslationConfigurationsTable() {
      return this.$TABLES.CHANNEL_TRANSLATION_CONFIGURATIONS.headers.map(header => {
        return {
          ...header, ...{
            text: this.$t(header.text)
          }
        }
      })
    },
    translationConfigurationsForUpdating() {
      const translationConfigurationsForUpdating = new Array()
      for (const translationConfigurationForDisplay of this.translationConfigurationsForDisplay) {
        if (translationConfigurationForDisplay.enabled) {
          translationConfigurationsForUpdating.push({
            languageId                : translationConfigurationForDisplay.languageId,
            enableTranslationToEnglish: translationConfigurationForDisplay.enableTranslationToEnglish
          })
        }
      }
      return translationConfigurationsForUpdating
    },
    canMachineTranslatableLanguagesBeSelected() {
      return [
        this.$CONSTANTS.TRANSLATION_PREFERENCES.HUMAN_ADDED,
        this.$CONSTANTS.TRANSLATION_PREFERENCES.MACHINE_PREFERRED,
        this.$CONSTANTS.TRANSLATION_PREFERENCES.MACHINE_ONLY
      ].includes(this.effectiveChannelTranslationPreferenceId)
    },
    channelFormTemplate() {
      let channelFormTemplate
      const channelFormTemplateId = this.formTemplatesOfChannels[this.channel.id]?.[ARRAY.FIRST]
      if (channelFormTemplateId) {
        channelFormTemplate = this.formTemplates.find(formTemplate => formTemplate.id === channelFormTemplateId)
      }
      return channelFormTemplate
    },
    channelFormTemplateName() {
      return this.channelFormTemplate?.name
    },
    channelFormTemplateFieldsForDisplay() {
      let channelFormTemplateFields

      if (this.channelFormTemplate) {
        const fieldNames          = this.getFormTemplateFieldNames(this.channelFormTemplate.id)
        channelFormTemplateFields = fieldNames?.length ? fieldNames.join(", ") : this.$t("741")
      }
      return channelFormTemplateFields
    },
    formTemplatesAddTableHeaders() {
      return getHeadersForTable(TABLE_NAMES.CHANNEL_FORM_TEMPLATES, this.$t.bind(this))
    },
    fieldsMap() {
      const fieldsMap = new Map()
      for (const field of this.fieldsV2) {
        fieldsMap.set(field.id, field)
      }
      return fieldsMap
    },
    formTemplateFieldsMap() {
      const formTemplateFieldsMap = new Map()
      for (const configuration of this.formTemplateConfigurations) {
        let fields = [this.fieldsMap.get(configuration.fieldId)]
        if (formTemplateFieldsMap.has(configuration.formTemplateId)) {
          fields = [...fields, ...formTemplateFieldsMap.get(configuration.formTemplateId)]
        }
        formTemplateFieldsMap.set(configuration.formTemplateId, fields)
      }
      return formTemplateFieldsMap
    },
    domainsMap() {
      const domainsMap = new Map()
      for (const domain of this.domains) {
        domainsMap.set(domain.id, domain)
      }
      return domainsMap
    },
    currentFormTemplateFields() {
      return this.formTemplateFieldsMap.get(this.channelFormTemplate?.id)
    },
    formTemplatesAddTableItems() {
      return this.formTemplates?.map(formTemplate => {
        const fieldNames = this.getFormTemplateFieldNames(formTemplate.id)
        return {
          id    : formTemplate.id,
          name  : formTemplate.name,
          fields: fieldNames?.length ? fieldNames.join(", ") : this.$t("741")
        }
      })
    },
    formTemplatesAddTableHeight() {
      const dataRowHeight      = 48
      const maxAvailableHeight = window.innerHeight - 430
      const maxDataRows        = Math.floor((maxAvailableHeight / dataRowHeight) - 1)
      let heightOfTable        = dataRowHeight // initialize with header height

      if (this.formTemplatesAddTableItems.length > maxDataRows) {
        heightOfTable += maxDataRows * dataRowHeight
      } else if (!this.formTemplatesAddTableItems.length) {
        heightOfTable += dataRowHeight // one row for "no data available"
      } else {
        heightOfTable += this.formTemplatesAddTableItems.length * dataRowHeight
      }

      return heightOfTable
    },
    automationAddSteps() {
      return [
        {
          stepNumber: 1,
          label     : this.$t("922")
        },
        {
          stepNumber: 2,
          label     : this.$t("923")
        },
        {
          stepNumber: 3,
          label     : this.$t("924")
        }
      ]
    },
    fieldForAutomation() {
      return this.fieldsMap.get(this.fieldIdForAutomation)
    },
    automationDetailsToDisplay() {
      const detailsToDisplay = [
        {
          label: this.$t("926"),
          value: this.domain?.name
        },
        {
          label: this.$t("927"),
          value: this.channel?.name
        },
        {
          label: this.$t("928"),
          value: this.channelFormTemplateName
        }
      ]

      if (this.currentAutomationAddStep > 1) {
        detailsToDisplay.push({
          label: this.$t("929"),
          value: this.fieldForAutomation.systemName
        }, {
          label: this.$t("930"),
          value: this.fieldForAutomation.label
        })
      }

      return detailsToDisplay
    },
    headersForAutomationRulesTable() {
      return getHeadersForTable(TABLE_NAMES.AUTOMATION_RULES, this.$t.bind(this))
    },
    footersForAutomationRulesTable() {
      return getFootersForTable(TABLE_NAMES.AUTOMATION_RULES, this.$t.bind(this))
    },
    automationRulesForTableDisplay() {

      return this.automationRules.sort((firstAutomationRule, secondAutomationRule) =>
        (firstAutomationRule.id - secondAutomationRule.id)).map(automationRule => {

        return {
          fieldValues: automationRule.fieldValues.join(", "),
          domain     : this.domainsMap.get(automationRule.domainId)?.name
        }
      })
    },
    isRemoveAutomationButtonDisplayed() {
      return this.isAutomationAdded && this.currentAutomationAddStep !== 1
    }
  },
  methods: {
    ...mapActions({
      updateChannel                    : "channels/updateChannel",
      updateTranslationConfigurations  : "channels/updateTranslationConfigurations",
      loadTranslationConfigurations    : "channels/loadTranslationConfigurations",
      notify                           : "shared/notify",
      addChannelLogo                   : "channels/addChannelLogo",
      removeChannelLogo                : "channels/removeChannelLogo",
      addChannelFormTemplates          : "channels/addChannelFormTemplates",
      removeChannelFormTemplate        : "channels/removeChannelFormTemplate",
      addAutomationAndRules            : "automations/addAutomationAndRules",
      createUpdateDeleteAutomationRules: "automations/createUpdateDeleteAutomationRules"
    }),
    ...mapMutations({
      resetChannelUpdateError: "channels/resetChannelUpdateError"
    }),
    async qrCodeDownload() {
      const qrCodeUrl = await QRCode.toDataURL([{
        data: `${this.reporterUrl}?organisation-code=${this.channel.organisationCode}`
      }])
      downloadFile(qrCodeUrl, undefined, `${this.localChannel.displayName}.png`)
    },
    computeTranslationConfigurationsForDisplay() {
      const translationConfigurationsForDisplay = new Array()
      const languagesCovered                    = new Array()
      const isSelectable                        = language => {
        if (this.effectiveChannelTranslationPreferenceId === 1) {
          return false
        } else if (this.effectiveChannelTranslationPreferenceId === 3) {
          return language.machineTranslatable
        } else if (this.effectiveChannelTranslationPreferenceId === 4) {
          return language.humanTranslatable
        }
        return true
      }
      // TODO select all machine translatable language is pending.
      for (const translationConfigurationOfChannel of this.translationConfigurationsOfChannel) {
        const language                           = this.languagesMap[translationConfigurationOfChannel.languageId]
        const translationConfigurationForDisplay = {
          languageId                : language.id,
          languageName              : `${language.name} - ${language.localName}`,
          enabled                   : true,
          enableTranslationToEnglish: translationConfigurationOfChannel.enableTranslationToEnglish,
          humanTranslatable         : language.humanTranslatable,
          machineTranslatable       : language.machineTranslatable,
          selectable                : language.shortName !== this.$CONSTANTS.LANGUAGE.CLIENT_DEFAULT.shortName
            && isSelectable(language)
        }
        languagesCovered.push(language.id)
        translationConfigurationsForDisplay.push(translationConfigurationForDisplay)
      }
      for (const language of this.languages) {
        if (!languagesCovered.includes(language.id)) {
          translationConfigurationsForDisplay.push({
            languageId                : language.id,
            languageName              : `${language.name} - ${language.localName}`,
            enabled                   : false,
            enableTranslationToEnglish: true,
            humanTranslatable         : language.humanTranslatable,
            machineTranslatable       : language.machineTranslatable,
            selectable                : isSelectable(language)
          })
        }
      }
      this.translationConfigurationsForDisplay =
        translationConfigurationsForDisplay.sort((language1, language2) => language1.languageId - language2.languageId)
    },
    openFileExplorer() {
      this.$refs.input_logo.click()
    },
    onLogoSelect(event) {
      this.channelLogo = event.target.files[0]
      if (this.channelLogo) {
        this.localChannel.logo = {
          name: this.channelLogo.name,
          size: this.channelLogo.size
        }
        this.addChannelLogo({
          id  : this.localChannel.id,
          file: this.channelLogo
        })
      }
    },
    async channelFormTemplatesAddHandler() {
      const payload = {
        id           : this.channel.id,
        formTemplates: this.formTemplatesToBeAdded.map(formTemplate => formTemplate.id)
      }
      this.addChannelFormTemplates(payload)
    },
    getFormTemplateFieldNames(formTemplateId) {
      return this.formTemplateFieldsMap
        ?.get(formTemplateId)
        ?.map(field => field?.systemName)
    },
    channelFormTemplateDeleteHandler() {
      this.formTemplateToBeRemoved = this.channelFormTemplateName
      const deletePayload          = {
        channelId     : this.channel.id,
        formTemplateId: this.channelFormTemplate.id
      }

      this.removeChannelFormTemplate(deletePayload)
    },
    async goToNextAutomationStep(currentStep) {
      const validationObserverRef = `automation_add_step_${currentStep}_observer`
      const isValid               = await this.$refs[validationObserverRef].validate()

      if (!isValid) {
        return
      }

      this.currentAutomationAddStep = currentStep + 1
    },
    addNewEmptyAutomationRule() {
      this.automationRules.push({ ...this.emptyAutomationRule })
    },
    closeAutomationAddDialog() {
      this.currentAutomationAddStep = 1
      this.fieldIdForAutomation     = null
      this.automationRules          = []
      this.showAutomationAddDialog  = false
      for (let i = 1; i < this.automationAddSteps.length; i++) {
        const validationObserverRef = `automation_add_step_${i}_observer`
        this.$refs[validationObserverRef].reset()
      }
    },
    fieldValuesSelectedInOtherRules(currentRuleIndex) {
      return this.automationRules.reduce(
        (selectedValues, automationRule, index) => {
          if (index !== currentRuleIndex) {
            return [...selectedValues, ...automationRule.fieldValues]
          }

          return selectedValues
        }, [])
    },
    optionListItemsForSelection(currentRuleIndex) {
      return this.optionListItems
        .filter(optionListItem => optionListItem.optionListId === this.fieldForAutomation?.optionListId &&
          !this.fieldValuesSelectedInOtherRules(currentRuleIndex).includes(optionListItem.name))
        .sort((firstOptionListItem, secondOptionListItem) =>
          firstOptionListItem.sortingOrder - secondOptionListItem.sortingOrder)
    },
    domainsSelectedInOtherRules(currentRuleIndex) {
      return this.automationRules.reduce(
        (selectedDomains, automationRule, index) => {
          if (index !== currentRuleIndex) {
            selectedDomains.push(automationRule.domainId)
          }

          return selectedDomains
        }, [])
    },
    domainsForSelection(currentRuleIndex) {
      return this.domains.filter(
        systemDomain => systemDomain.id !== this.domain.id &&
          !this.domainsSelectedInOtherRules(currentRuleIndex).includes(systemDomain.id) &&
          !systemDomain.archived
      )
    },
    getDeletedRules() {
      const deletedRules = []
      for (const initialAutomationRule of this.initialAutomationRules) {
        let ruleDeleted = true
        for (const automationRule of this.automationRules) {
          if (initialAutomationRule.id === automationRule.id) {
            ruleDeleted = false
          }
        }
        if (ruleDeleted) {
          deletedRules.push(initialAutomationRule.id)
        }
      }
      return deletedRules
    },
    handleAutomationUpdate() {
      this.isEditingAutomationRules = true
      const payload                 = {
        id  : this.automationIdForTheChannel,
        data: {
          add: this.automationRules.filter(automationRule => !automationRule.id)
            .map(automationRule => ({
              value: {
                channelId : this.channel.id,
                conditions: [{
                  formTemplateId: this.channelFormTemplate.id,
                  fieldId       : this.fieldIdForAutomation,
                  fieldValues   : [...automationRule.fieldValues]
                }],
                result: {
                  domainId: automationRule.domainId
                }
              }
            })),
          update: this.automationRules.filter(
            automationRule => !!automationRule.id
          ).filter(
            automationRule =>
              JSON.stringify(automationRule) !== JSON.stringify(this.initialAutomationRulesMap[automationRule.id])
          ).map(automationRule => ({
            id   : automationRule.id,
            value: {
              channelId : this.channel.id,
              conditions: [{
                formTemplateId: this.channelFormTemplate.id,
                fieldId       : this.fieldIdForAutomation,
                fieldValues   : [...automationRule.fieldValues]
              }],
              result: {
                domainId: automationRule.domainId
              }
            }
          })),
          delete: this.getDeletedRules()
        }
      }
      this.createUpdateDeleteAutomationRules(payload)
    },
    handleAutomationSave() {
      if (this.isAutomationAdded) {
        this.handleAutomationUpdate()
      } else {
        const payload = {
          automationData: {
            event   : AUTOMATION_EVENT.SPEAK_UP_ISSUE_CREATE,
            ruleName: AUTOMATION_RULE_NAME.DOMAIN_CHANGE,
            domainId: this.domain.id
          },
          automationRulesData: {
            add: this.automationRules.map(automationRule => ({
              value: {
                channelId : this.channel.id,
                conditions: [{
                  formTemplateId: this.channelFormTemplate.id,
                  fieldId       : this.fieldIdForAutomation,
                  fieldValues   : [...automationRule.fieldValues]
                }],
                result: {
                  domainId: automationRule.domainId
                }
              }
            }))
          }
        }
        this.addAutomationAndRules(payload)
      }
    },
    handleAutomationDelete() {
      const arrayOfAutomationIdsToDelete = []

      for( const automationRuleToDelete of this.domainChangeOnSpeakUpIssueCreationRulesForChannel ) {
        arrayOfAutomationIdsToDelete.push(automationRuleToDelete.id)
      }
      const payload                  = {
        data: {
          delete: arrayOfAutomationIdsToDelete
        },
        id: this.automationIdForTheChannel
      }
      this.isDeletingAutomationRules = true
      this.createUpdateDeleteAutomationRules(payload)
    },
    shouldDisplayBackButton() {
      return (this.currentAutomationAddStep > 1 && !this.isAutomationAdded)
    },
    shouldDisableReviewButton() {
      return this.isAutomationAdded &&
        JSON.stringify(this.automationRules) === JSON.stringify(this.initialAutomationRules)
    },
    handleTabClickEvent(hash) {
      this.channelConfigurationTab = this.tabs[hash] || this.tabs[ANCHOR_LINKS.WEB_AND_APP]
      if (this.$route.hash !== hash) {
        this.$router.push({ hash })
      }
    }
  },
  watch: {
    showAutomationAddDialog: {
      immediate: true,
      handler  : function(newValue) {
        if (!this.isAutomationAdded && newValue) {
          if (this.currentFormTemplateFields.length === 1) {
            this.fieldIdForAutomation = this.currentFormTemplateFields[0].id
          }
        }
        if (this.isAutomationAdded && newValue) {
          this.currentAutomationAddStep = 2
          if (!this.fieldIdForAutomation) {
            this.fieldIdForAutomation = this
              .domainChangeOnSpeakUpIssueCreationRulesForChannel[0].value.conditions[0].fieldId
          }
          this.automationRules = JSON.parse(JSON.stringify(this.initialAutomationRules))
        }
      }
    },
    channel: {
      immediate: true,
      handler  : function(newValue) {
        if (!this.localChannel) {
          this.localChannel = { ...newValue }
        }
      }
    },
    "localChannel.name": {
      immediate: true,
      handler  : function() {
        if (this.nameUpdateError) {
          this.resetChannelUpdateError(["name"])
        }
      }
    },
    "localChannel.displayName": {
      immediate: true,
      handler  : function() {
        if (this.displayNameUpdateError) {
          this.resetChannelUpdateError(["displayName"])
        }
      }
    },
    canComputeTranslationConfigurationsForDisplay: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          this.computeTranslationConfigurationsForDisplay()
        }
      }
    },
    isTranslationConfigurationsUpdated: {
      immediate: false,
      handler  : function(newValue) {
        if (newValue) {
          this.notify({
            type: "primary",
            text: "445"
          })
        }
      }
    },
    effectiveChannelTranslationPreferenceId: {
      immediate: false,
      handler  : function(newValue) {
        if ([1, 3, 4].includes(newValue)) {
          this.loadTranslationConfigurations(+this.$route.params.id)
        }
      }
    },
    isEnabledUpdated: {
      immediate: false,
      handler  : function(newValue) {
        if (newValue) {
          this.showDisableChannelDialog = false
          this.localChannel.enabled     = false
          this.notify({
            type      : "success",
            text      : "434",
            parameters: {
              id: this.localChannel.id
            }
          })
        }
      }
    },
    isUpdatingEnabled: {
      immediate: false,
      handler  : function(newValue) {
        this.$DECISIONS.CHANNEL_DISABLE.pActions[1].buttonProps.loading  = newValue
        this.$DECISIONS.CHANNEL_DISABLE.pActions[0].buttonProps.disabled = newValue
      }
    },
    isAddingChannelLogo: {
      immediate: true,
      handler  : function(newValue) {
        this.isLogoRemovable = !newValue
      }
    },
    isChannelLogoRemoved: {
      immediate: false,
      handler  : function(newValue) {
        if (newValue) {
          this.localChannel.logo = null
        }
      }
    },
    translationConfigurationsForDisplay: {
      immediate: false,
      deep     : true,
      handler  : function(newValue) {
        if (newValue) {
          for (const translationConfiguration of this.translationConfigurationsForDisplay) {
            if (translationConfiguration.machineTranslatable && !translationConfiguration.enabled) {
              this.allMachineTranslatableLanguagesEnabled = false
              return
            }
          }
          this.allMachineTranslatableLanguagesEnabled = true
        }
      }
    },
    allMachineTranslatableLanguagesEnabled: {
      immediate: false,
      handler  : function(newValue) {
        if (newValue) {
          for (const translationConfiguration of this.translationConfigurationsForDisplay) {
            if (translationConfiguration.machineTranslatable) {
              translationConfiguration.enabled = true
            }
          }
        }
      }
    },
    translationConfigurationsOfChannel: {
      immediate: false,
      deep     : true,
      handler  : function(newValue) {
        if (newValue && this.canComputeTranslationConfigurationsForDisplay) {
          this.computeTranslationConfigurationsForDisplay()
        }
      }
    },
    channelFormTemplatesAdded: {
      handler: function(value) {
        if (value) {
          this.notify({
            type      : "success",
            text      : "832",
            parameters: {
              formTemplateName: this.channelFormTemplateName
            }
          })
          this.showAddFormTemplateDialog = false
        }
      }
    },
    isChannelFormTemplateRemoved: {
      handler: function(value) {
        if (value) {
          this.notify({
            type      : "success",
            text      : "840",
            parameters: {
              formTemplateName: this.formTemplateToBeRemoved
            }
          })
          this.showRemoveFormTemplateDialog = false
          this.editChannelFormTemplate      = false
          this.formTemplateToBeRemoved      = null
        }
      }
    },
    showAddFormTemplateDialog: {
      handler: function(value) {
        if (!value) {
          this.formTemplatesToBeAdded = []
        }
      }
    },
    fieldIdForAutomation: {
      handler: function() {
        if (!this.isAutomationAdded) {
          this.automationRules = [{ ...this.emptyAutomationRule }]
        }
      }
    },
    isAutomationAndRulesAdded: {
      handler: function(value) {
        if (value) {
          this.notify({
            type: "success",
            text: "954"
          })
          this.closeAutomationAddDialog()
        }
      }
    },
    isAutomationRulesCreatedUpdatedDeleted: {
      handler: function(value) {
        if (value) {
          if(this.isDeletingAutomationRules) {
            this.notify({
              type: "success",
              text: "1128"
            })
            this.isDeletingAutomationRules  = false
            this.showRemoveAutomationDialog = false
          } else if(this.isEditingAutomationRules) {
            this.notify({
              type: "success",
              text: "957"
            })
            this.isEditingAutomationRules = false
          }
          this.closeAutomationAddDialog()
        }
      }
    },
    automationRules: {
      handler: function(value) {
        if (value) {
          this.shouldDisableReviewButton()
        }
      }
    },
    "$route.hash": {
      immediate: true,
      handler  : function(hash) {
        if(hash){
          this.handleTabClickEvent(hash)
        }
      }
    }
  }
}